import React from "react";
import { motion } from "framer-motion";
import { Badge } from "./ui/badge";
import "./news.css";

const newsData = [
  {
    id: 1,
    title: "新製品発表のお知らせ",
    description: "革新的な新製品のラインナップを発表いたしました。",
    category: "製品情報",
    date: "2024-03-15",
  },
  {
    id: 2,
    title: "年末年始営業時間のご案内",
    description: "年末年始の営業時間について変更がございます。",
    category: "お知らせ",
    date: "2024-03-14",
  },
  {
    id: 3,
    title: "サービス機能アップデート",
    description: "より使いやすくなった新機能をリリースしました。",
    category: "アップデート",
    date: "2024-03-13",
  },
];

export const News = () => {
  return (
    <section id="news" className="news-container py-16 px-4 md:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex items-center justify-center mb-2">
          <Badge variant="secondary" className="text-sm font-medium">
            News
          </Badge>
        </div>
        <h2 className="text-3xl font-bold text-center mb-12">最新のお知らせ</h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {newsData.map((news, index) => (
            <motion.div
              key={news.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="border-b border-gray-300 pb-4 mb-4"
            >
              <div className="flex items-center justify-between mb-2">
                <Badge variant="outline" className="text-xs">
                  {news.category}
                </Badge>
                <span className="text-sm text-muted-foreground">
                  {news.date}
                </span>
              </div>
              <h3 className="text-xl font-bold">{news.title}</h3>
              <p>{news.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
