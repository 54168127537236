import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center" style={{ padding: "24px 0" }}>
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2 style={{ textAlign: "center" }}>事業内容</h2>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-xs-12 col-sm-8 col-md-4"
                  style={{ textAlign: "center" }}
                >
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
