import React from "react";

export const Collabo = (props) => {
  return (
    <div id="features" className="text-center" style={{ padding: "24px 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-12 section-title">
            <h2 style={{ textAlign: "center" }}>パートナー</h2>
          </div>
        </div>
        <div className="row" style={{ textAlign: "center" }}>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-4 col-sm-3 col-md-2">
                  <img src={d.icon} alt={d.title} className="img-fluid" style={{ maxWidth: "64px" }} />
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
