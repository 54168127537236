import React from "react";

export const Services = (props) => {
  return (
    <div id="services" style={{ backgroundColor: "#608CFD", color: "#FFFFFF" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/qrc2.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6 mt-36">
            <div className="services-text">
              <h2 style={{ color: "#FFFFFF", marginTop: "20px" }}>サービス</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3 style={{ color: "#FFFFFF" }}>事例</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>・{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>・{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
