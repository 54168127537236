import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { ToastContainer, toast } from 'react-toastify'; // トースト通知のインポート

const initialState = {
  name: "",
  email: "",
  message: "",
  contactType: "message", // 追加: contactTypeの初期値を設定
};

export const Contact = (props) => {
  const [{ name, email, message, contactType }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message, contactType);

    if (contactType === "meeting") {
      window.location.href = "https://timerex.net/s/info_cd2f_c601/b4f221be";
      return;
    }
    
    if (contactType === "download") {
      window.location.href = "https://deeptell.s3.ap-northeast-1.amazonaws.com/%E5%9B%BD%E5%86%85%E5%88%9D%E3%81%AE%E9%87%8F%E5%AD%90%E3%83%AA%E3%82%B6%E3%83%BC%E3%83%90%E3%82%92%E5%BC%B7%E3%81%BF%E3%81%A8%E3%81%97%E3%81%9F%E7%94%9F%E6%88%90AI%E3%82%B9%E3%82%BF%E3%83%BC%E3%83%88%E3%82%A2%E3%83%83%E3%83%97_%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BEDeepTeLL.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAUHDZCKRX3FDWLAV2%2F20250101%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20250101T073826Z&X-Amz-Expires=300&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEN%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLW5vcnRoZWFzdC0xIkYwRAIgda89dz61LvoVelqzfqMGTh%2B7eQRGuytqw35vcmU3KZQCIDeu%2Fs6PkIrjuU1rvut9HfhVmn5Gih%2FIpAci%2Fx2Eak0GKvUCCLn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMMjkwMTY0MjAwNTU5IgwdvdYqQuIja5Su7ZgqyQKmLIDYVJw3Y9SMesxKTvhy%2FKkj9SwbbZtbat4FOJVtdZks91PDTV2uuTImvExzUfjjzT7lcR93QCJ0Nfu1nAhwWRklnIE2LYZHRAy%2BfXcSYkXLIZq%2BPIavAiH5BDzHz8egBBGSTnFG8K1oNZD6tzKKMWX%2F80cYEnWTFbF1ZHE207wAM1SlGzWK06Shs7qc7RwsYmmuFzEa7%2BJjviSAHgkgef22nmrVGYK2%2FvKdG7sR4SyWbSqZSdmAd4fiu77OicC5CvNaG%2F7JaYOWWbxQV1gStKPX97qUkI8tVuHeHQtrQGVryvNUHJ25%2F7LY%2BjFaCyVvPQwlVgWRTqdtu4NoFQlPaeKyMCMYt2045Fcz2pDnU9nWSFFM5IzIksYex2XpZU%2Fnp3nfrGeSJP00LLxcCKgiMtNkBh81hBCuqDCB0fSAqdVAHsDwuxBRLzDasNO7Bjq0AkBRc86%2FiyG5%2FlLxGBOz4VgAI9G7KZwFBM3cTaPgZINWAQxVQeok%2Fa4sfx%2FStGxRcTKaR6pO3ZSUA7FwkxkcBkmNNHqIyP7N%2BclG0kzk55ny%2FYAuy1njXoRl%2FkJUxIlshdoBw7mGtLX%2BNdWakM%2BuldGzw8P4qPB2xRueVXyHc7cAg%2FPjKUTF4Gqa%2BW4DrnSzXmba9Hya1ybuwPJAO6EJvubpHg1gUgKiBCj4XFECmqNroYjDXrbsIxd2qQAcIRIv9eLusBoq7eSdrrjHd2J1Zh%2FNhOz2%2F6DQ%2B6M4T8GvmqGX05UrVKfQPVOKBzeWIPXGh8%2BLShaFSqmvrNljaFwTTrcj5PVRl%2FVjTAraDD3lL9lHSolDyOfYx75maboiFqGXALo1HJjcDYDyhrhCCG6TIXwZpByA&X-Amz-Signature=d4a82540d9ed9e3efb009bbde637cfcdbba7d205972e923f1083363658b84441&X-Amz-SignedHeaders=host&response-content-disposition=inline";
      return;
    }
    
    emailjs
      .sendForm("service_tl9faat", "template_sc0e9mr", e.target, "hezeixpMbUL6iyGuw")
      .then(
        (result) => {
          console.log(result.text);
          toast.success("メール送信完了"); // トースト通知の表示
          clearState();
        },
        (error) => {
          console.log(error.text);
          toast.error("メール送信に失敗しました。"); // エラートースト通知の表示
        }
      );
  };

  return (
    <div>
      <ToastContainer /> 
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>お問い合わせ</h2>
                <p>
                   下記のフォームにご記入の上、メールをお送りください。
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <select
                    name="contactType"
                    id="contactType"
                    className="form-control"
                    required
                    onChange={handleChange}
                  >
                    <option value="message">メッセージ</option>
                    <option value="download">資料ダウンロード</option>
                    <option value="meeting">面談調整</option>
                  </select>
                  <p className="help-block text-danger"></p>
                </div>
                {contactType === "message" && (
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                )}
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {contactType === "message" && "送信"}
                  {contactType === "download" && "資料ダウンロード"}
                  {contactType === "meeting" && "面談調整"}
                </button>
              </form>
              
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 All Rights Reserved by{" "}
            <a href="https://deeptell.co.jp" rel="nofollow">
              DeepTeLL
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
