import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { Badge } from "./ui/badge";
import "./faq.css";

const faqData = [
  {
    question: "サービスの利用方法について",
    answer: "当社のサービスは、直感的なインターフェースで簡単にご利用いただけます。詳細な手順はマニュアルをご参照ください。",
    category: "使い方",
  },
  {
    question: "料金プランについて",
    answer: "様々なニーズに対応した複数の料金プランをご用意しております。各プランの詳細は料金ページでご確認いただけます。",
    category: "料金",
  },
  {
    question: "アカウントの登録方法",
    answer: "ホームページ上部の「新規登録」ボタンから、必要事項を入力するだけで簡単に登録できます。",
    category: "アカウント",
  },
];

export const FAQ = () => {
  return (
    <section className="faq-container py-8 px-4 sm:py-12 sm:px-6 lg:py-16 lg:px-8 bg-secondary/50">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-center mb-4 sm:mb-6">
          <Badge variant="secondary" className="text-sm font-medium">
            FAQ
          </Badge>
        </div>
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-8 sm:mb-10 lg:mb-12">
          よくあるご質問
        </h2>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Accordion type="single" collapsible className="space-y-4">
            {faqData.map((faq, index) => (
              <AccordionItem
                key={index}
                value={`item-${index}`}
                className="bg-background rounded-lg border px-4 sm:px-6 lg:px-8 faq-item"
              >
                <AccordionTrigger className="text-left py-4 hover:no-underline">
                  <div className="flex flex-col gap-2">
                    <Badge variant="outline" className="w-fit text-xs">
                      {faq.category}
                    </Badge>
                    <span className="font-medium">{faq.question}</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="text-muted-foreground pb-4 faq-answer">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </motion.div>
      </div>
    </section>
  );
};
